import { List, Map, fromJS } from "immutable";
import { ticketingConstants } from "../constants/ticketingConstants";
import {
	ticketAssigneeKeyConstants,
	ticketItemKey,
	ticketPriorityConstants,
} from "../components/ticketing/helper";

/**
 * Ticketing redux
 */

const initialState = Map({
	action: false,
	request: false,
	ticketList: List(),
	ticketMeta: Map(),
	ticketStats: null,
	tempTicket: Map({
		name: "",
		item: null,
		itemName: null,
		priority: ticketPriorityConstants.low,
		dueDate: null,
		description: null,
		assigneeKey: null,
		assignee: null,
		owner: null,
	}),
	ticketValidity: Map({
		name: false,
		item: false,
		itemName: false,
		assignee: false,
	}),
	ticket: Map(),
	tempFiles: List(),
	ticketCommentsCount: 0,
	successMessage: "",
	errorMessage: "Something went wrong",
});

export default (state = initialState, action) => {
	/**
	 * UPDATE temp files
	 * @param {Array} files
	 * @returns
	 */
	const updateTempFiles = (files) => {
		const tempFiles = state.get("tempFiles")?.toJS() || [];
		const newFiles = [...tempFiles, ...files];

		return state.set("tempFiles", fromJS(newFiles));
	};

	/**
	 * REMOVE temp file from temp files
	 * @param {number} index
	 * @returns
	 */
	const removeTempTicketFile = (index) => {
		const tempFiles = state.get("tempFiles")?.toJS() || [];
		const newFiles = tempFiles.filter((_, i) => i !== index);

		return state.set("tempFiles", fromJS(newFiles));
	};

	/**
	 * SET ticket object and temp ticket object
	 *
	 * @param {object} data - ticket object
	 * @returns
	 */
	const getTicket = (data) => {
		const assigneeKey =
			data?.companies?.length > 0
				? ticketAssigneeKeyConstants.company
				: data?.users?.length > 0
					? ticketAssigneeKeyConstants.user
					: null;
		const assignee =
			data?.companies?.length > 0
				? data?.companies[0]
				: data?.users?.length > 0
					? data?.users[0]
					: null;
		const item = !!data?.order
			? ticketItemKey.offer
			: !!data?.building
				? ticketItemKey.building
				: !!data?.job
					? ticketItemKey.job
					: null;
		const itemName = !!data?.order
			? data?.order
			: !!data?.building
				? data?.building
				: !!data?.job
					? data?.job
					: null;

		const files = data?.files?.map((file) => file?.file) || [];

		const tempTicket = {
			name: data?.name,
			owner: data?.owner?.id || data?.createdBy?.id || null,
			assignee:
				assigneeKey === ticketAssigneeKeyConstants.user
					? `${assigneeKey}_${assignee?.user.id}`
					: `${assigneeKey}_${assignee?.company.id}`,
			assigneeKey,
			item,
			itemName: { value: itemName?.id, label: itemName?.name },
			priority: data?.priority,
			dueDate: data?.dueDate || null,
			description: data?.description || null,
			tempFiles: files,
		};

		return state
			.set("ticket", fromJS(data))
			.set("initialTempTicket", fromJS(tempTicket))
			.set("tempTicket", fromJS(tempTicket))
			.set("tempFiles", fromJS(files))
			.setIn(["ticket", "assigneeKey"], fromJS(assigneeKey))
			.setIn(["ticket", "assignee"], fromJS(assignee))
			.setIn(["ticket", "item"], fromJS(item))
			.setIn(["ticket", "itemName"], fromJS(itemName));
	};
	switch (action.type) {
		case ticketingConstants.GET_TICKETS:
			return state
				.set("ticketList", fromJS(action.data?.result))
				.set("ticketMeta", fromJS(action.data?.meta));

		case ticketingConstants.TICKET_STATS:
			return state.set("ticketStats", fromJS(action.data));

		case ticketingConstants.UPDATE_TEMP_TICKET:
			return state
				.setIn(["tempTicket", action.data.fieldKey], action.data.value)
				.setIn(
					["ticketValidity", action.data.fieldKey],
					!!action.data.value ? true : false
				);

		case ticketingConstants.UPDATE_TEMP_TICKET_FILES:
			return updateTempFiles(action.data);

		case ticketingConstants.REMOVE_TEMP_TICKET_FILE:
			return removeTempTicketFile(action.data);

		case ticketingConstants.CLEAR_TICKET_REQUEST_STATE:
			return state
				.set("action", false)
				.set("request", false)
				.set("errorMessage", "")
				.set("successMessage", "");

		case ticketingConstants.TICKET_ACTION_FAILURE:
			return state
				.set("action", false)
				.set("request", true)
				.set("errorMessage", fromJS(action.data));

		case ticketingConstants.TICKET_ACTION_SUCCESS:
			return state
				.set("successMessage", fromJS(action.data))
				.set("action", true)
				.set("request", true);

		case ticketingConstants.CLEAR_TEMP_TICKET:
			return state
				.set("tempTicket", initialState.get("tempTicket"))
				.set("initialTempTicket", initialState.get("initialTempTicket"))
				.set("tempFiles", initialState.get("tempFiles"))
				.set("ticketValidity", initialState.get("ticketValidity"));

		case ticketingConstants.CLEAR_SINGLE_TICKET:
			return state.set("ticket", Map()).set("activityComments", null);

		case ticketingConstants.RESET_TEMP_TICKET_NAME:
			return state.setIn(
				["tempTicket", "name"],
				state.getIn(["initialTempTicket", "name"])
			);
		case ticketingConstants.RESET_TEMP_TICKET_DESCRIPTION:
			return state.setIn(
				["tempTicket", "description"],
				state.getIn(["initialTempTicket", "description"])
			);

		case ticketingConstants.RESET_TEMP_TICKET_ATTACHMENTS:
			return state.set(
				"tempFiles",
				state.getIn(["initialTempTicket", "tempFiles"])
			);

		case ticketingConstants.RESET_TEMP_TICKET_INFORMATION:
			return state
				.setIn(
					["tempTicket", "assignee"],
					state.getIn(["initialTempTicket", "assignee"])
				)
				.setIn(
					["tempTicket", "assigneeKey"],
					state.getIn(["initialTempTicket", "assigneeKey"])
				)
				.setIn(
					["tempTicket", "item"],
					state.getIn(["initialTempTicket", "item"])
				)
				.setIn(
					["tempTicket", "itemName"],
					state.getIn(["initialTempTicket", "itemName"])
				)
				.setIn(
					["tempTicket", "priority"],
					state.getIn(["initialTempTicket", "priority"])
				)
				.setIn(
					["tempTicket", "dueDate"],
					state.getIn(["initialTempTicket", "dueDate"])
				)
				.setIn(
					["tempTicket", "name"],
					state.getIn(["initialTempTicket", "name"])
				)
				.setIn(
					["tempTicket", "owner"],
					state.getIn(["initialTempTicket", "owner"])
				);

		case ticketingConstants.GET_TICKET:
			return getTicket(action.data);

		case ticketingConstants.GET_ACTIVITY_COMMENTS:
			return state.set("activityComments", fromJS(action.data));

		case ticketingConstants.CLEAR_ACTIVITY_COMMENT:
			return state.set("activityComments", null);

		case ticketingConstants.SET_COMMENTS_COUNTER:
			return state.set("ticketCommentsCount", action.data);

		case ticketingConstants.INCREMENT_COMMENTS_COUNTER:
			const ticketCommentsCount = +state.get("ticketCommentsCount") + 1;

			return state.set("ticketCommentsCount", ticketCommentsCount);

		case ticketingConstants.CLEAR_STATE:
			return initialState;

		default:
			return state;
	}
};
