import { Map, fromJS, List } from "immutable";
import { jobConstants } from "../constants/jobConstants";
import {
	getDiffPercentageBetweenTwoNumbers,
	getNewValueFromDiffPercentage,
} from "../utils/math-helper/MathHelper";
import { statusConstants, userRolesConstants } from "../constants/constants";
import { customAdjustmentConstants } from "../components/administration/automatisation-constants/single-view/helper";

/**
 * Job reducer redux
 */

const initialState = Map({
	job: Map(),
	action: false,
	request: false,
	errorResponse: "",
	jobList: Map(),
	jobMeta: Map(),
	tempFilesList: Map(),
	tempJob: Map({
		building: null,
		jobDescription: [],
		initJobDescription: true,
		discipline: null,
		disciplineComponentCategoryLods: [
			{
				disciplineId: null,
				componentCategoryId: null,
				lodId: 1,
				isSupported: false,
				valid: false,
			},
		],
		normalisation: "",
		customisation: "",
		quality_assurance: "",
		inputFormat: null,
		size: null,
		buildingName: "",
		buildingType: null,
		deliveryFormat: null,
		lodSpecification: null,
		template: null,
		exports: [],
		disciplineLevels: fromJS([]),
		validity: Map({
			building: false,
			lodSpecification: false,
			inputFormat: false,
			deliveryFormat: false,
			template: false,
		}),
	}),
	tempAssignedUsers: Map({
		normalisation: Map({
			deliveryDate: null,
			users: List([]),
		}),
		customisation: Map({
			deliveryDate: null,
			users: List([]),
		}),
		qa: Map({
			deliveryDate: null,
			users: List([]),
		}),
	}),
	assignedUsers: Map({
		normalisation: Map({
			deliveryDate: null,
			users: List([]),
		}),
		customisation: Map({
			deliveryDate: null,
			users: List([]),
		}),
		qa: Map({
			deliveryDate: null,
			users: List([]),
		}),
	}),
	jobEstimation: Map(),
	tempJobEstimation: Map(),
	stats: null,
	jobComments: {
		list: Map(),
		meta: Map(),
	},
	bimModel: Map(),
	jobCompanies: List(),
	jobUsers: List(),
	asyncLoadJobs: List(),
	successMessage: "Success!",
	errorMessage: "Something went wrong",
});

const getEstimateTypeAndValue = (item) => {
	let type = null;
	let value = null;

	if (+item.percentageAdjustment > 0) {
		type = customAdjustmentConstants.percentage;
		value = item.percentageAdjustment;
	} else if (+item.timeAdjustment > 0) {
		type = customAdjustmentConstants.time;
		value = item.timeAdjustment;
	} else if (+item.velocityAdjustment > 0) {
		type = customAdjustmentConstants.velocity;
		value = item.velocityAdjustment;
	}

	return { type, value };
};

const getCustomEstimateTypeAndValue = (item) => {
	let customValue = null;
	let isString = false;

	if (typeof item.customPercentageAdjustment?.toString() === "string") {
		isString = true;
		customValue = item.customPercentageAdjustment;
	} else if (typeof item.customTimeAdjustment?.toString() === "string") {
		customValue = item.customTimeAdjustment;
		isString = true;
	} else if (typeof item.customVelocityAdjustment?.toString() === "string") {
		isString = true;
		customValue = item.customVelocityAdjustment;
	}

	return { customValue, isString };
};

export default (state = initialState, action) => {
	/**
	 * Set initial levels per decipline inside TEMP JOB object
	 * @param {*} data - { building - {levels}, disciplines}
	 * @returns
	 */
	const setDisciplineLevels = (data) => {
		const { building, disciplines } = data;
		const levels = building?.levels.sort((a, b) => {
			return b.level - a.level;
		});

		let stateDisciplineLevels = [];

		disciplines &&
			disciplines.valueSeq().forEach((discipline) => {
				let stateLevels = [];
				levels &&
					levels.length > 0 &&
					levels.forEach((item) => {
						stateLevels.push({
							id: item.id,
							key: `${discipline.get("id")}_floor__${item.level}`,
							isRoof: item.isRoof,
							level: item.level,
							file: null,
							valid: false,
							extension: null,
						});
					});

				stateDisciplineLevels.push({
					discipline: discipline.get("id"),
					levels: stateLevels,
				});
			});

		return state.setIn(
			["tempJob", "disciplineLevels"],
			fromJS(stateDisciplineLevels)
		);
	};

	/**
	 * Set initial component category and lods per decipline inside TEMP JOB object
	 * @param {*} data - { disciplines }
	 * @returns
	 */
	const setLods = (data) => {
		const { disciplines } = data;

		const stateDisciplines = [];

		disciplines &&
			disciplines.valueSeq().forEach((discipline) => {
				const disciplineComponentCategories = discipline.get(
					"disciplineComponentCategories"
				);

				disciplineComponentCategories &&
					disciplineComponentCategories.valueSeq().forEach((element) => {
						stateDisciplines.push({
							disciplineId: discipline.get("id"),
							isSupported: element.get("isSupported"),
							componentCategoryId: element.getIn(["componentCategory", "id"]),
							lodId: element.get("isSupported") ? 3 : 1,
							valid: element.get("isSupported") ? true : false,
						});
					});
			});

		return state.setIn(
			["tempJob", "disciplineComponentCategoryLods"],
			stateDisciplines
		);
	};

	/**
	 * Set file per level inside TEMP JOB object, depends on disciplineId and field name (key)
	 * @param {object} data - { key, value, disciplineId, extension}
	 * @returns
	 */
	const setFilePerLevel = (data) => {
		const { key, value, disciplineId, extension, path } = data;
		const stateDisciplineLevels = state.getIn(["tempJob", "disciplineLevels"]);

		const disciplineLevels =
			stateDisciplineLevels && stateDisciplineLevels?.toJS();

		const disciplineLevel =
			disciplineLevels &&
			disciplineLevels.find(
				(disciplineLevel) => +disciplineLevel.discipline === +disciplineId
			);

		const levels = disciplineLevel?.levels;
		const disciplineLevelIndex = disciplineLevels.indexOf(disciplineLevel);
		const levelIndex = levels && levels.findIndex((level) => level.key === key);

		return state
			.setIn(
				[
					"tempJob",
					"disciplineLevels",
					disciplineLevelIndex,
					"levels",
					levelIndex,
					"file",
				],
				value
			)
			.setIn(
				[
					"tempJob",
					"disciplineLevels",
					disciplineLevelIndex,
					"levels",
					levelIndex,
					"valid",
				],
				value ? true : false
			)
			.setIn(
				[
					"tempJob",
					"disciplineLevels",
					disciplineLevelIndex,
					"levels",
					levelIndex,
					"extension",
				],
				extension
			)
			.setIn(
				[
					"tempJob",
					"disciplineLevels",
					disciplineLevelIndex,
					"levels",
					levelIndex,
					"path",
				],
				path
			);
	};

	/**
	 * Update lods,component category per decipline inside TEMP JOB object
	 * @param {*} data - {componentCategoryId, lodValueId, disciplineId}
	 * @returns
	 */
	const updateLods = (data) => {
		const { componentCategoryId, lodValueId, disciplineId } = data;
		const disciplineComponentCategoryLods = state.getIn([
			"tempJob",
			"disciplineComponentCategoryLods",
		]);

		const index =
			disciplineComponentCategoryLods &&
			disciplineComponentCategoryLods.findIndex(
				(item) =>
					item.disciplineId === disciplineId &&
					item.componentCategoryId === componentCategoryId
			);
		return state
			.setIn(
				["tempJob", "disciplineComponentCategoryLods", index, "lodId"],
				lodValueId
			)
			.setIn(
				["tempJob", "disciplineComponentCategoryLods", index, "valid"],
				+lodValueId > 1 ? true : false
			);
	};

	/**
	 * Set export version - TEMP Job
	 * @param {*} data
	 * @returns
	 */
	const setExports = (data) => {
		const { id, exportId, isSelected } = data;

		const exports = state.getIn(["tempJob", "exports"]);
		const findIndex = exports.findIndex((item) => item.id === id);

		if (findIndex !== -1) {
			if (!isSelected) {
				const tempFilterExports = exports.filter((item) => +item.id !== +id);

				return state.setIn(["tempJob", "exports"], tempFilterExports);
			} else {
				return state
					.setIn(["tempJob", "exports", findIndex, "exportId"], exportId)
					.setIn(
						["tempJob", "exports", findIndex, "valid"],
						exportId ? true : false
					);
			}
		}

		let tempExports = [
			{
				id: id,
				exportId: exportId,
				price: null,
				valid: exportId ? true : false,
			},
		];
		if (exports.length > 0) {
			tempExports = tempExports.concat(exports);
		}

		return state.setIn(["tempJob", "exports"], tempExports);
	};

	/**
	 * Set job description inside TEMP JOB object
	 * @param {*} data - { id, value}
	 * @returns
	 */
	const setJobDescription = (data) => {
		const { id, fieldKey, value } = data;

		const jobDescription = state.getIn(["tempJob", "jobDescription"]);

		const findIndex = jobDescription.findIndex(
			(description) => description.id === id
		);

		if (findIndex !== -1) {
			return state.setIn(
				["tempJob", "jobDescription", findIndex, fieldKey],
				value
			);
		}

		let tempJobDescription = [
			{
				id: id,
				description: value,
			},
		];
		if (jobDescription.length > 0) {
			tempJobDescription = tempJobDescription.concat(jobDescription);
		}

		return state.setIn(["tempJob", "jobDescription"], tempJobDescription);
	};

	/**
	 * Set initial job description for all job statuses inside TEMP JOB object
	 * @returns
	 */
	const setInitJobDescription = () => {
		const jobStatus = state.get("jobStatus");
		const jobDescription = [];

		jobStatus &&
			jobStatus.valueSeq().forEach((status) => {
				jobDescription.push({
					id: status.get("id"),
					description: "",
				});
			});

		return state
			.setIn(["tempJob", "jobDescription"], jobDescription)
			.setIn(["tempJob", "initJobDescription"], false);
	};

	//----------------Estimation disciplines------------------

	/**
	 * Set job estimation
	 * @param {*} data - job object
	 */
	const setJobEstimation = (job) => {
		const { jobDisciplineComponentCategoryLods, status } = job;
		const isGreaterThanZero =
			+jobDisciplineComponentCategoryLods[0]?.defaultPercentageDifference > 0;
		const toFixedVal = isGreaterThanZero ? 3 : 2;
		const disciplines = {};

		jobDisciplineComponentCategoryLods &&
			jobDisciplineComponentCategoryLods.map((item) => {
				const consultantEstimateHoursFixed = Number(
					item.counsultantEstimateHours
				)?.toFixed(1);
				const defaultPercentageDifference =
					Number(item.defaultPercentageDifference)?.toFixed(2) || null;
				const systemEstimateHours = Number(item.systemEstimateHours)?.toFixed(
					2
				);
				const consultantEstimateHours = Number(
					item.counsultantEstimateHours
				)?.toFixed(toFixedVal);

				const customisationHours =
					consultantEstimateHours * (1 - +item.bimifyModelSaving);

				const consultantSystemEstimateDifferencePercentage = Number(
					item.consultantSystemEstimateDifferencePercentage
				)?.toFixed(2);

				const isNone = item.lod.type === "0";

				if (!disciplines[+item.discipline.id]) {
					disciplines[+item.discipline.id] = {
						id: item.discipline.id,
						type: item.discipline.type,
						consultantTotalHours: isNone ? 0 : +consultantEstimateHours || 0,
						systemTotalHours: isNone ? 0 : +systemEstimateHours || 0,
						customisationTotalHours: isNone ? 0 : +customisationHours || 0,
						estimations: [
							{
								id: item.id,
								lod: {
									id: item.lod.id,
									type: item.lod.type,
								},
								bimifyModelSaving: item?.bimifyModelSaving || 0,
								componentCategory: {
									id: item.componentCategory.id,
									type: item.componentCategory.type,
								},
								consultantEstimateHoursFixed,
								defaultPercentageDifference,
								systemEstimateHours,
								consultantSystemEstimateDifferencePercentage,
								consultantEstimateHours,
								customisationHours,
							},
						],
					};
				} else {
					disciplines[+item.discipline.id].consultantTotalHours += isNone
						? 0
						: +consultantEstimateHours || 0;
					disciplines[+item.discipline.id].systemTotalHours += isNone
						? 0
						: +systemEstimateHours || 0;

					disciplines[+item.discipline.id].customisationTotalHours += isNone
						? 0
						: +customisationHours || 0;

					disciplines[+item.discipline.id].estimations.push({
						id: item.id,
						lod: {
							id: item.lod.id,
							type: item.lod.type,
						},
						bimifyModelSaving: item?.bimifyModelSaving || 0,
						componentCategory: {
							id: item.componentCategory.id,
							type: item.componentCategory.type,
						},
						consultantEstimateHoursFixed,
						defaultPercentageDifference,
						systemEstimateHours,
						consultantSystemEstimateDifferencePercentage,
						consultantEstimateHours,
						customisationHours,
					});
				}
				return disciplines;
			});
		return state
			.setIn(["job", "status"], status)
			.setIn(["jobEstimation", "disciplines"], fromJS(disciplines))
			.setIn(["tempJobEstimation", "stopCalculate"], false)
			.setIn(["tempJobEstimation", "disciplines"], fromJS(disciplines))
			.setIn(["tempJobEstimation", "stopCalculate"], false);
	};

	/**
	 * Re calculate TEMP job estimation
	 * @param {object} job
	 * @returns
	 */
	const reCalculateTempJobEstimation = (job) => {
		const { jobDisciplineComponentCategoryLods } = job;
		const disciplineId =
			jobDisciplineComponentCategoryLods[0]?.discipline?.id?.toString();
		const estimations = [];
		const systemTotalHours = state.getIn([
			"tempJobEstimation",
			"disciplines",
			disciplineId,
			"systemTotalHours",
		]);
		let customisationTotalHours = 0;

		jobDisciplineComponentCategoryLods &&
			jobDisciplineComponentCategoryLods.map((item) => {
				const isNone = item.lod.type === "0";

				const consultantEstimateHours = Number(
					item.counsultantEstimateHours
				)?.toFixed(2);

				const consultantEstimateHoursFixed = Number(
					consultantEstimateHours
				)?.toFixed(1);

				const customisationHours =
					consultantEstimateHours * (1 - +item.bimifyModelSaving);

				customisationTotalHours += isNone ? 0 : +customisationHours || 0;

				return estimations.push({
					id: item.id,
					lod: {
						id: item.lod.id,
						type: item.lod.type,
					},
					componentCategory: {
						id: item.componentCategory.id,
						type: item.componentCategory.type,
					},

					bimifyModelSaving: item?.bimifyModelSaving || 0,
					defaultPercentageDifference: 0,
					customisationHours,
					systemEstimateHours: Number(item.systemEstimateHours)?.toFixed(2),
					consultantEstimateHoursFixed,
					consultantEstimateHours,
					consultantSystemEstimateDifferencePercentage: Number(
						item.consultantSystemEstimateDifferencePercentage
					)?.toFixed(2),
				});
			});

		return state
			.setIn(
				["tempJobEstimation", "disciplines", disciplineId, "estimations"],
				fromJS(estimations)
			)
			.setIn(
				[
					"tempJobEstimation",
					"disciplines",
					disciplineId,
					"consultantTotalHours",
				],
				systemTotalHours
			)
			.setIn(
				[
					"tempJobEstimation",
					"disciplines",
					disciplineId,
					"customisationTotalHours",
				],
				customisationTotalHours
			)
			.setIn(["tempJobEstimation", "stopCalculate"], false);
	};

	/**
	 * UPDATE TEMP job estimation
	 * @param {object} data - {id, value}
	 * @returns
	 */
	const updateTempJobEstimation = (data) => {
		const { id, value } = data;
		const disciplineId = data.disciplineId?.toString();

		if (!!id) {
			const estimationList = state.getIn([
				"tempJobEstimation",
				"disciplines",
				disciplineId,
				"estimations",
			]);

			let consultantTotalHours = state.getIn([
				"tempJobEstimation",
				"disciplines",
				disciplineId,
				"consultantTotalHours",
			]);

			let customisationTotalHours = state.getIn([
				"tempJobEstimation",
				"disciplines",
				disciplineId,
				"customisationTotalHours",
			]);

			const indexEstimation = estimationList?.findIndex(
				(item) => +item.get("id") === +id
			);

			const systemEstimateHours = state.getIn([
				"tempJobEstimation",
				"disciplines",
				disciplineId,
				"estimations",
				indexEstimation,
				"systemEstimateHours",
			]);
			const consultantEstimateHours = state.getIn([
				"tempJobEstimation",
				"disciplines",
				disciplineId,
				"estimations",
				indexEstimation,
				"consultantEstimateHours",
			]);
			const bimifyModelSaving = state.getIn([
				"tempJobEstimation",
				"disciplines",
				disciplineId,
				"estimations",
				indexEstimation,
				"bimifyModelSaving",
			]);
			const customisationHours = state.getIn([
				"tempJobEstimation",
				"disciplines",
				disciplineId,
				"estimations",
				indexEstimation,
				"customisationHours",
			]);

			const newConsultantTotalHours =
				+consultantTotalHours - +consultantEstimateHours + +value;

			const newCustomisationHours = +value * (1 - +bimifyModelSaving);

			const newCustomisationTotalHours =
				+customisationTotalHours - +customisationHours + newCustomisationHours;
			return state
				.setIn(
					[
						"tempJobEstimation",
						"disciplines",
						disciplineId,
						"consultantTotalHours",
					],
					newConsultantTotalHours
				)
				.setIn(
					[
						"tempJobEstimation",
						"disciplines",
						disciplineId,
						"customisationTotalHours",
					],
					newCustomisationTotalHours
				)
				.setIn(
					[
						"tempJobEstimation",
						"disciplines",
						disciplineId,
						"estimations",
						indexEstimation,
						"consultantEstimateHours",
					],
					value
				)
				.setIn(
					[
						"tempJobEstimation",
						"disciplines",
						disciplineId,
						"estimations",
						indexEstimation,
						"consultantEstimateHoursFixed",
					],
					value
				)
				.setIn(
					[
						"tempJobEstimation",
						"disciplines",
						disciplineId,
						"estimations",
						indexEstimation,
						"customisationHours",
					],
					newCustomisationHours
				)
				.setIn(
					[
						"tempJobEstimation",
						"disciplines",
						disciplineId,
						"estimations",
						indexEstimation,
						"consultantSystemEstimateDifferencePercentage",
					],
					getDiffPercentageBetweenTwoNumbers(
						value,
						+parseFloat(+systemEstimateHours)?.toFixed(2)
					)
				)
				.setIn(["tempJobEstimation", "stopCalculate"], false);
		} else {
			const estimations = state.getIn([
				"tempJobEstimation",
				"disciplines",
				disciplineId,
				"estimations",
			]);
			const estimationsJS = estimations?.toJS() || null;
			let consultantTotalHours = 0;
			let customisationTotalHours = 0;

			const newEstimations =
				estimationsJS &&
				estimationsJS.map((item) => {
					const newValue = +getNewValueFromDiffPercentage(
						parseFloat(+item.systemEstimateHours)?.toFixed(2),
						+value
					);

					const isNone = item.lod.type === "0";
					const consultantEstimateHours = newValue?.toFixed(3);
					consultantTotalHours += isNone ? 0 : +consultantEstimateHours || 0;

					const customisationHours =
						consultantEstimateHours * (1 - +item.bimifyModelSaving);

					customisationTotalHours += isNone ? 0 : +customisationHours || 0;

					return {
						...item,
						consultantEstimateHoursFixed: newValue?.toFixed(1),
						customisationHours: customisationHours,

						consultantEstimateHours,
						defaultPercentageDifference: +value || null,
						consultantSystemEstimateDifferencePercentage:
							getDiffPercentageBetweenTwoNumbers(
								newValue,
								+parseFloat(+item.systemEstimateHours)?.toFixed(2)
							),
					};
				});

			return state
				.setIn(
					[
						"tempJobEstimation",
						"disciplines",
						disciplineId,
						"consultantTotalHours",
					],
					+consultantTotalHours
				)
				.setIn(
					[
						"tempJobEstimation",
						"disciplines",
						disciplineId,
						"customisationTotalHours",
					],
					+customisationTotalHours
				)
				.setIn(
					["tempJobEstimation", "disciplines", disciplineId, "estimations"],
					fromJS(newEstimations)
				)
				.setIn(["tempJobEstimation", "stopCalculate"], true);
		}
	};

	const updateTempJobEstimationBimifyModelSaving = (data) => {
		const { id, value } = data;
		const disciplineId = data.disciplineId?.toString();

		const estimationList = state.getIn([
			"tempJobEstimation",
			"disciplines",
			disciplineId,
			"estimations",
		]);

		let customisationTotalHours = state.getIn([
			"tempJobEstimation",
			"disciplines",
			disciplineId,
			"customisationTotalHours",
		]);

		const indexEstimation = estimationList?.findIndex(
			(item) => +item.get("id") === +id
		);

		const consultantEstimateHours = state.getIn([
			"tempJobEstimation",
			"disciplines",
			disciplineId,
			"estimations",
			indexEstimation,
			"consultantEstimateHours",
		]);

		const customisationHours = state.getIn([
			"tempJobEstimation",
			"disciplines",
			disciplineId,
			"estimations",
			indexEstimation,
			"customisationHours",
		]);

		const newCustomisationHours =
			+consultantEstimateHours * (1 - +(+value / 100));

		const newCustomisationTotalHours =
			+customisationTotalHours - +customisationHours + newCustomisationHours;

		return state
			.setIn(
				[
					"tempJobEstimation",
					"disciplines",
					disciplineId,
					"estimations",
					indexEstimation,
					"bimifyModelSaving",
				],
				+value / 100
			)
			.setIn(
				[
					"tempJobEstimation",
					"disciplines",
					disciplineId,
					"customisationTotalHours",
				],
				newCustomisationTotalHours
			)

			.setIn(
				[
					"tempJobEstimation",
					"disciplines",
					disciplineId,
					"estimations",
					indexEstimation,
					"customisationHours",
				],
				newCustomisationHours
			)

			.setIn(["tempJobEstimation", "stopCalculate"], false);
	};

	//-----------------------------------------------

	//----------------Estimations Exports------------

	const setExportEstimations = (data) => {
		const exportEstimations = {
			totalEstimate: 0,
			customTotalEstimate: 0,
			defaultPercentage: 0,
			exports: [],
		};

		data &&
			data.map((item) => {
				const { type, value } = getEstimateTypeAndValue(item);
				const { customValue, isString = false } =
					getCustomEstimateTypeAndValue(item);

				exportEstimations.totalEstimate += +value;
				exportEstimations.customTotalEstimate += isString
					? +customValue
					: +customValue || +value;

				exportEstimations.exports.push({
					id: item.id,
					name: item?.jobExport?.export?.type || null,
					type,
					systemEstimate: Number(value).toFixed(1) || 0,
					customEstimate:
						Number(isString ? customValue : customValue || value).toFixed(1) ||
						0,
				});

				return exportEstimations;
			});
		return state
			.setIn(["jobEstimation", "exports"], fromJS(exportEstimations))
			.setIn(["tempJobEstimation", "exports"], fromJS(exportEstimations));
	};

	const reCalculateTempExportEstimations = (data) => {
		const exportEstimations = {
			totalEstimate: 0,
			customTotalEstimate: 0,
			defaultPercentage: 0,
			exports: [],
		};

		data &&
			data.map((item) => {
				const { type, value } = getEstimateTypeAndValue(item);

				exportEstimations.totalEstimate += +value;
				exportEstimations.customTotalEstimate += +value;

				exportEstimations.exports.push({
					id: item.id,
					name: item?.export?.type || null,
					type,
					systemEstimate: Number(value).toFixed(1) || 0,
					customEstimate: Number(value).toFixed(1) || 0,
				});

				return exportEstimations;
			});

		return state.setIn(
			["tempJobEstimation", "exports"],
			fromJS(exportEstimations)
		);
	};

	const updateTempExportEstimations = (data) => {
		const { id = null, value } = data;

		const exports = state.getIn(["tempJobEstimation", "exports", "exports"]);
		let customTotalEstimate = state.getIn([
			"tempJobEstimation",
			"exports",
			"customTotalEstimate",
		]);

		if (!!id) {
			const index =
				exports && exports.findIndex((item) => +item.get("id") === +id);
			const customEstimate = state.getIn([
				"tempJobEstimation",
				"exports",
				"exports",
				index,
				"customEstimate",
			]);

			customTotalEstimate = +customTotalEstimate - +customEstimate + +value;

			return state
				.setIn(
					["tempJobEstimation", "exports", "exports", index, "customEstimate"],
					value
				)
				.setIn(
					["tempJobEstimation", "exports", "customTotalEstimate"],
					customTotalEstimate
				);
		} else {
			const exportsJS = (exports && exports?.toJS()) || [];
			const newExports =
				exportsJS &&
				exportsJS.map((item) => {
					const newCustomValue = +getNewValueFromDiffPercentage(
						parseFloat(+item.systemEstimate)?.toFixed(1),
						+value
					);

					customTotalEstimate =
						+customTotalEstimate - +item.customEstimate + +newCustomValue;

					return {
						...item,
						customEstimate: +newCustomValue?.toFixed(1),
					};
				});

			return state
				.setIn(["tempJobEstimation", "exports", "exports"], fromJS(newExports))
				.setIn(
					["tempJobEstimation", "exports", "customTotalEstimate"],
					customTotalEstimate
				)
				.setIn(["tempJobEstimation", "exports", "defaultPercentage"], value);
		}
	};

	//----------------Estimations Services------------

	const setServiceEstimations = (data) => {
		const serviceEstimations = {
			totalEstimate: 0,
			customTotalEstimate: 0,
			services: [],
		};

		data &&
			data.map((item) => {
				const { type, value } = getEstimateTypeAndValue(item);
				const { customValue, isString = false } =
					getCustomEstimateTypeAndValue(item);

				serviceEstimations.totalEstimate += +value;
				serviceEstimations.customTotalEstimate += isString
					? +customValue
					: +customValue || +value;

				serviceEstimations.services.push({
					id: item.id,
					name: item?.manual?.title || null,
					type,
					systemEstimate: Number(value)?.toFixed(1) || 0,
					customEstimate:
						Number(isString ? customValue : customValue || value).toFixed(1) ||
						0,
				});

				return serviceEstimations;
			});

		return state
			.setIn(["jobEstimation", "services"], fromJS(serviceEstimations))
			.setIn(["tempJobEstimation", "services"], fromJS(serviceEstimations));
	};

	const reCalculateTempServiceEstimations = (data) => {
		const serviceEstimations = {
			totalEstimate: 0,
			customTotalEstimate: 0,
			services: [],
		};

		data &&
			data.map((item) => {
				const { type, value } = getEstimateTypeAndValue(item);

				serviceEstimations.totalEstimate += +value;
				serviceEstimations.customTotalEstimate += +value;

				serviceEstimations.services.push({
					id: item.id,
					name: item?.manual?.title || null,
					type,
					systemEstimate: Number(value)?.toFixed(1) || 0,
					customEstimate: Number(value)?.toFixed(1) || 0,
				});

				return serviceEstimations;
			});

		return state.setIn(
			["tempJobEstimation", "services"],
			fromJS(serviceEstimations)
		);
	};

	const updateTempServiceEstimations = (data) => {
		const { id, value } = data;

		const services = state.getIn(["tempJobEstimation", "services", "services"]);
		let customTotalEstimate = state.getIn([
			"tempJobEstimation",
			"services",
			"customTotalEstimate",
		]);

		if (!!id) {
			const index =
				services && services.findIndex((item) => +item.get("id") === +id);

			const customEstimate = state.getIn([
				"tempJobEstimation",
				"services",
				"services",
				index,
				"customEstimate",
			]);

			customTotalEstimate = +customTotalEstimate - +customEstimate + +value;

			return state
				.setIn(
					[
						"tempJobEstimation",
						"services",
						"services",
						index,
						"customEstimate",
					],
					value
				)
				.setIn(
					["tempJobEstimation", "services", "customTotalEstimate"],
					customTotalEstimate
				);
		} else {
			const servicesJS = (services && services?.toJS()) || [];
			const newServices =
				servicesJS &&
				servicesJS.map((item) => {
					const newCustomValue = +getNewValueFromDiffPercentage(
						parseFloat(+item.systemEstimate)?.toFixed(1),
						+value
					);

					customTotalEstimate =
						+customTotalEstimate - +item.customEstimate + +newCustomValue;

					return {
						...item,
						customEstimate: +newCustomValue?.toFixed(1),
					};
				});

			return state
				.setIn(
					["tempJobEstimation", "services", "services"],
					fromJS(newServices)
				)
				.setIn(
					["tempJobEstimation", "services", "customTotalEstimate"],
					customTotalEstimate
				)
				.setIn(["tempJobEstimation", "services", "defaultPercentage"], value);
		}
	};

	//------------------------------------------------

	//Assigned User functions

	/**
	 * ADD TEMP assigned user
	 * @param {object} data - { status}
	 * @returns
	 */
	const addTempAssignedUser = (data) => {
		const { status } = data;
		let tempAssignedUsers =
			state.getIn(["tempAssignedUsers", status, "users"])?.toJS() || [];

		tempAssignedUsers.push({
			tempId: Math.floor(100000 + Math.random() * 900000),
			id: null,
			name: "",
		});

		return state.setIn(
			["tempAssignedUsers", status, "users"],
			fromJS(tempAssignedUsers)
		);
	};

	/**
	 * UPDATE TEMP assigned user
	 * @param {object} data - { tempId, id, user, status}
	 * @returns
	 */
	const updateTempAssignedUser = (data) => {
		const { tempId, id, user, status } = data;
		let tempAssignedUsers =
			state.getIn(["tempAssignedUsers", status, "users"])?.toJS() || [];

		const userExists = tempAssignedUsers.find((item) => item.id === id);

		//if user already exists in tempAssignedUsers
		if (!!userExists) {
			const filterTempAssignedUsers = tempAssignedUsers.filter(
				(item) => item.tempId !== tempId
			);

			return state.setIn(
				["tempAssignedUsers", status, "users"],
				fromJS(filterTempAssignedUsers)
			);
		}

		//if user not exists in tempAssignedUsers
		const findIndex = tempAssignedUsers.findIndex(
			(item) => item.tempId === tempId
		);

		return state
			.setIn(["tempAssignedUsers", status, "users", findIndex, "id"], id)
			.setIn(
				["tempAssignedUsers", status, "users", findIndex, "name"],
				`${user.firstName} ${user.lastName}`
			)
			.setIn(
				["tempAssignedUsers", status, "users", findIndex, "imageLink"],
				user.imageLink
			);
	};

	/**
	 * REMOVE TEMP assigned user
	 *
	 * @param {number} tempId - unique temp identifier
	 * @param {string} status - job status
	 * @returns
	 */
	const removeTempAssignedUser = ({ tempId, status }) => {
		let tempAssignedUsers =
			state.getIn(["tempAssignedUsers", status, "users"])?.toJS() || [];

		//Remove user from tempAssignedUsers with tempId
		const filterTempAssignedUsers = tempAssignedUsers.filter(
			(item) => item.tempId !== tempId
		);

		return state.setIn(
			["tempAssignedUsers", status, "users"],
			fromJS(filterTempAssignedUsers)
		);
	};

	/**
	 * GET Assigned users
	 * @param {Array} users - list of assigned users
	 * @returns
	 */
	const getAssignedUsers = (users) => {
		let normalisationUsers = [];
		let customisationUsers = [];
		let qaUsers = [];
		//const job = (users && users[0]?.job) || null;

		users &&
			users.map((user) => {
				//Set user for Normalisation
				if (user?.assigneeUser?.role?.type === userRolesConstants.NORMALIZER) {
					return normalisationUsers.push({
						tempId: user.assigneeUser.id,
						id: user.assigneeUser.id,
						imageLink: user.assigneeUser.imageLink,
						name: `${user.assigneeUser.firstName} ${user.assigneeUser.lastName}`,
					});
				}
				//Set user for Customisation
				else if (
					user?.assigneeUser?.role?.type === userRolesConstants.CUSTOMIZER
				) {
					return customisationUsers.push({
						tempId: user.assigneeUser.id,
						id: user.assigneeUser.id,
						imageLink: user.assigneeUser.imageLink,
						name: `${user.assigneeUser.firstName} ${user.assigneeUser.lastName}`,
					});
				}
				//Set user for QA
				else if (
					user?.assigneeUser?.role?.type === userRolesConstants.QUALITY_CONTROL
				) {
					return qaUsers.push({
						tempId: user.assigneeUser.id,
						id: user.assigneeUser.id,
						imageLink: user.assigneeUser.imageLink,
						name: `${user.assigneeUser.firstName} ${user.assigneeUser.lastName}`,
					});
				}
				return "";
			});

		return (
			state
				.setIn(
					["tempAssignedUsers", statusConstants.NORMALISATION, "users"],
					fromJS(normalisationUsers)
				)
				// .setIn(
				// 	["tempAssignedUsers", statusConstants.NORMALISATION, "deliveryDate"],
				// 	job?.normalisationDeliveryDate || null
				// )
				.setIn(
					["tempAssignedUsers", statusConstants.CUSTOMISATION, "users"],
					fromJS(customisationUsers)
				)
				// .setIn(
				// 	["tempAssignedUsers", statusConstants.CUSTOMISATION, "deliveryDate"],
				// 	job?.customisationDeliveryDate || null
				// )
				.setIn(
					["tempAssignedUsers", statusConstants.QA, "users"],
					fromJS(qaUsers)
				)
				// .setIn(
				// 	["tempAssignedUsers", statusConstants.QA, "deliveryDate"],
				// 	job?.qaDeliveryDate || null
				// )
				.setIn(
					["assignedUsers", statusConstants.NORMALISATION, "users"],
					fromJS(normalisationUsers)
				)
				// .setIn(
				// 	["assignedUsers", statusConstants.NORMALISATION, "deliveryDate"],
				// 	job?.normalisationDeliveryDate || null
				// )
				.setIn(
					["assignedUsers", statusConstants.CUSTOMISATION, "users"],
					fromJS(customisationUsers)
				)
				// .setIn(
				// 	["assignedUsers", statusConstants.CUSTOMISATION, "deliveryDate"],
				// 	job?.customisationDeliveryDate || null
				// )
				.setIn(["assignedUsers", statusConstants.QA, "users"], fromJS(qaUsers))
		);
		// .setIn(
		// 	["assignedUsers", statusConstants.QA, "deliveryDate"],
		// 	job?.qaDeliveryDate || null
		// );
	};

	const getDeliveryDate = (data) => {
		return state
			.setIn(
				["tempAssignedUsers", statusConstants.NORMALISATION, "deliveryDate"],
				data?.normalisationDeliveryDate || null
			)
			.setIn(
				["tempAssignedUsers", statusConstants.NORMALISATION, "validDate"],
				true
			)
			.setIn(
				["tempAssignedUsers", statusConstants.CUSTOMISATION, "deliveryDate"],
				data?.customisationDeliveryDate || null
			)
			.setIn(
				["tempAssignedUsers", statusConstants.CUSTOMISATION, "validDate"],
				true
			)
			.setIn(
				["tempAssignedUsers", statusConstants.QA, "deliveryDate"],
				data?.qaDeliveryDate || null
			)
			.setIn(["tempAssignedUsers", statusConstants.QA, "validDate"], true)
			.setIn(
				["assignedUsers", statusConstants.NORMALISATION, "deliveryDate"],
				data?.normalisationDeliveryDate || null
			)
			.setIn(
				["assignedUsers", statusConstants.CUSTOMISATION, "deliveryDate"],
				data?.customisationDeliveryDate || null
			)
			.setIn(
				["assignedUsers", statusConstants.QA, "deliveryDate"],
				data?.qaDeliveryDate || null
			);
	};

	const addTempDeliveryDate = (data) => {
		const { status, date } = data;

		return state.setIn(["tempAssignedUsers", status, "deliveryDate"], date);
	};

	/**
	 * UPDATE ASYNC LOAD JOBS
	 *
	 * @param {object} data - { jobs, values, isForSingleView = false }
	 * @returns
	 */
	const updateAsyncLoadJobs = (data) => {
		const { jobs, values, isForSingleView = false } = data;

		const jobsJS = state.get("asyncLoadJobs")?.toJS() || [];
		let findJobs = [];

		if (isForSingleView) {
			const id = values && [+values?.value];

			findJobs =
				(jobsJS.length > 0 &&
					id?.length > 0 &&
					jobsJS.filter((item) => id.includes(+item.id))) ||
				[];

			if (findJobs.length === 0 && !!values?.value) {
				findJobs = [{ name: values?.label, id: values?.value }];
			}
		} else {
			const valuesIds = values && values.map((item) => +item?.value);
			if (valuesIds?.length > 0)
				findJobs =
					(jobsJS.length > 0 &&
						jobsJS.filter((item) => valuesIds.includes(+item.id))) ||
					[];
		}

		let tempJobs = [...findJobs, ...jobs];

		//Remove duplicates from tempJobs
		const ids = tempJobs.map(({ id }) => id);
		const filteredJobs = tempJobs.filter(
			({ id }, index) => !ids.includes(id, index + 1)
		);

		return state.set("asyncLoadJobs", fromJS(filteredJobs));
	};

	switch (action.type) {
		case jobConstants.GET_JOBS:
			return state
				.set("jobList", fromJS(action.data.result))
				.set("jobMeta", fromJS(action.data.meta));

		case jobConstants.SET_TEMP_FIELD_VALUE:
			return state
				.setIn(["tempJob", action.data.stateKey], action.data.value)
				.setIn(
					["tempJob", "validity", action.data.stateKey],
					action.data.isValid
				);

		case jobConstants.GET_JOB_STATUS_FILE:
			return state.setIn(
				["bimModel", "file"],
				fromJS(action.data?.file || null)
			);

		case jobConstants.CHANGE_FORGE_VIEWER_URN:
			return state
				.setIn(["bimModel", "viewerUrn"], action.data)
				.setIn(["bimModel", "previewLink"], null);

		case jobConstants.CHANGE_FILE_URL:
			return state
				.setIn(["bimModel", "previewLink"], action.data?.previewLink || null)
				.setIn(["bimModel", "viewerUrn"], null);

		case jobConstants.SET_FILE_PER_LEVEL:
			return setFilePerLevel(action.data);

		case jobConstants.SET_LEVELS:
			return setDisciplineLevels(action.data);

		case jobConstants.SET_BUILDING_ID:
			return state
				.set("buildingId", action.data?.buildingId)
				.setIn(["tempJob", "disciplineLevels"], Map());

		case jobConstants.SET_LODS:
			return setLods(action.data);

		case jobConstants.UPDATE_LODS:
			return updateLods(action.data);

		case jobConstants.JOB_ACTION_SUCCESS:
			if (action.message) {
				return state
					.set("successMessage", action.message)
					.set("action", true)
					.set("request", true);
			} else {
				return state.set("action", true).set("request", true);
			}

		case jobConstants.JOB_ACTION_FAILURE:
			return state
				.set("action", false)
				.set("request", true)
				.set("errorMessage", fromJS(action.error));

		case jobConstants.SET_SUCCESS_MESSAGE:
			return state.set("successMessage", fromJS(action.data));

		case jobConstants.SET_ERROR_MESSAGE:
			return state.set("errorMessage", fromJS(action.data));

		case jobConstants.CLEAR_JOB_REQUEST_STATE:
			return state
				.set("action", false)
				.set("request", false)
				.set("errorMessage", "")
				.set("successMessage", "");

		case jobConstants.CLEAR_TEMP_JOB:
			return state
				.set("tempJob", initialState.get("tempJob"))
				.set("buildingId", null);

		case jobConstants.CLEAR_JOB:
			return state.set("job", initialState.get("job"));

		case jobConstants.JOB_STATS:
			return state.set("stats", fromJS(action.data.result));

		case jobConstants.SET_EXPORTS:
			return setExports(action.data);

		case jobConstants.GET_JOB_STATUS:
			return state.set("jobStatus", fromJS(action.data));

		case jobConstants.SET_JOB_DESCRIPTION:
			return setJobDescription(action.data);

		case jobConstants.SET_INIT_JOB_DESCRIPTION:
			return setInitJobDescription();

		case jobConstants.GET_JOB:
			return state
				.set("job", fromJS(action.data))
				.set(`job_${action?.step || 0}`, fromJS(action.data));

		case jobConstants.GET_COMMENTS:
			return state.setIn(["jobComments", "list"], fromJS(action.data));

		case jobConstants.SET_TEMP_JOB:
			const data = action.data;

			const jobExports = data.jobExports;
			const jobDescription = data.jobStatusDescriptions;
			const inputFormat = data.jobInputFormats?.[0]?.inputFormat?.id;

			const tempJobExports = [];

			//Exports
			jobExports &&
				jobExports.map((jobExport) => {
					return tempJobExports.push({
						id: jobExport.export?.parent
							? jobExport.export?.parent.id
							: jobExport.export.id,
						exportId: jobExport.export.id,

						valid: true,
					});
				});

			//Descriptions
			const tempJobDescription = [];
			jobDescription &&
				jobDescription.map((item) => {
					return tempJobDescription.push({
						id: item.jobStatus?.id,
						description: item.description,
						manualDescription: item?.manualDescription,
					});
				});

			//Discipline Levels

			let stateDisciplineLevels = [];
			const buildingLevelFiles = data?.jobDisciplineBuildingLevelFiles;

			let stateLevels = [];

			buildingLevelFiles &&
				buildingLevelFiles.forEach((buildingLevelFile, i) => {
					const isLast = i + 1 === +buildingLevelFiles.length;

					if (isLast) {
						stateLevels.push({
							id: buildingLevelFile?.buildingLevel?.id,
							key: `${buildingLevelFile?.discipline?.id}_floor__${buildingLevelFile?.buildingLevel?.level}`,
							level: buildingLevelFile?.buildingLevel?.level,
							file: buildingLevelFile?.file?.id || null,
							valid: false,
							extension: buildingLevelFile?.file?.extension || null,
							path: buildingLevelFile?.file?.path || null,
							elevation: buildingLevelFile?.buildingLevel?.elevation || null,
							thickness: buildingLevelFile?.buildingLevel?.thickness || null,
						});
					}

					if (
						(i > 0 &&
							buildingLevelFile?.discipline.id !==
								buildingLevelFiles[i - 1]?.discipline.id) ||
						isLast
					) {
						stateLevels =
							stateLevels &&
							stateLevels.sort((a, b) => {
								return b.level - a.level;
							});

						stateDisciplineLevels.push({
							discipline: isLast
								? buildingLevelFile?.discipline.id
								: buildingLevelFiles[i - 1]?.discipline.id,
							levels: stateLevels,
						});

						stateLevels = [];
					}
					// ---------------------------------------------------

					stateLevels.push({
						id: buildingLevelFile?.buildingLevel?.id,
						key: `${buildingLevelFile?.discipline?.id}_floor__${buildingLevelFile?.buildingLevel?.level}`,
						level: buildingLevelFile?.buildingLevel?.level,
						file: buildingLevelFile?.file?.id || null,
						valid: false,
						extension: buildingLevelFile?.file?.extension || null,
						path: buildingLevelFile?.file?.path || null,
						elevation: buildingLevelFile?.buildingLevel?.elevation || null,
						thickness: buildingLevelFile?.buildingLevel?.thickness || null,
					});
				});

			//Building data
			const building = {
				name: data.building?.name || null,
				size: data.building?.size || null,
				inputFormat: inputFormat || null,
				type: data.building.type?.id || null,
				//lodSpecification: data.building?.lodSpecification?.id || null,
				levels: data.building?.levelsTotal || null,
				uniqueLevels: data.building?.uniqueLevels || null,
			};

			//Job data
			const jobInfo = {
				service: "Create",
				deliveryFormat: data.jobDeliveryFormats?.[0]?.deliveryFormat?.id || "",
				template: data.revitFile?.id || null,
			};

			return state
				.setIn(["tempJob", "inputFormat"], inputFormat ? inputFormat : "")
				.setIn(["tempJob", "lodSpecification"], data.lodSpecification?.id || "")
				.setIn(["tempJob", "size"], data.building?.size || null)
				.setIn(["tempJob", "buildingName"], data.building?.name || null)
				.setIn(["tempJob", "buildingType"], data.building?.type?.id || null)
				.setIn(["tempJob", "template"], data.revitFile?.id || null)
				.setIn(
					["tempJob", "deliveryFormat"],
					data.jobDeliveryFormats?.[0]?.deliveryFormat?.id || ""
				)
				.setIn(["tempJob", "building"], data.building?.id || "")
				.setIn(["tempJob", "exports"], tempJobExports)
				.setIn(["tempJob", "jobDescription"], tempJobDescription)
				.setIn(["tempJob", "disciplineLevels"], fromJS(stateDisciplineLevels))
				.setIn(["tempJob", "initialBuildingInfo"], fromJS(building))
				.setIn(["tempJob", "initialJobInfo"], fromJS(jobInfo))
				.setIn(["tempJob", "buildingInfo"], fromJS(building))
				.setIn(["tempJob", "jobInfo"], fromJS(jobInfo))
				.setIn(["tempJob", "initialExports"], tempJobExports);

		case jobConstants.GET_JOB_FILES:
			return state
				.set("jobFilesList", fromJS(action.data.result))
				.set("jobFilesMeta", fromJS(action.data.meta));

		//----------------Estimation Disciplines------------
		case jobConstants.GET_JOB_SPECIFICATION:
			return setJobEstimation(action.data);

		case jobConstants.UPDATE_TEMP_JOB_ESTIMATION:
			return updateTempJobEstimation(action.data);

		case jobConstants.UPDATE_TEMP_JOB_ESTIMATION_BIMIFY_MODEL_SAVING:
			return updateTempJobEstimationBimifyModelSaving(action.data);

		case jobConstants.RE_CALCULATE_TEMP_JOB_SPECIFICATION:
			return reCalculateTempJobEstimation(action.data);

		//----------------Estimation Exports------------
		case jobConstants.SET_EXPORT_ESTIMATIONS:
			return setExportEstimations(action.data);

		case jobConstants.UPDATE_TEMP_EXPORT_ESTIMATIONS:
			return updateTempExportEstimations(action.data);

		case jobConstants.RE_CALCULATE_TEMP_EXPORT_ESTIMATIONS:
			return reCalculateTempExportEstimations(action.data);

		case jobConstants.RESET_TEMP_EXPORT_ESTIMATION:
			return state.setIn(
				["tempJobEstimation", "exports"],
				state.getIn(["jobEstimation", "exports"])
			);

		//----------------Estimation Services------------
		case jobConstants.SET_SERVICE_ESTIMATIONS:
			return setServiceEstimations(action.data);

		case jobConstants.UPDATE_TEMP_SERVICE_ESTIMATIONS:
			return updateTempServiceEstimations(action.data);

		case jobConstants.RE_CALCULATE_TEMP_SERVICE_ESTIMATIONS:
			return reCalculateTempServiceEstimations(action.data);

		case jobConstants.RESET_TEMP_SERVICE_ESTIMATION:
			return state.setIn(
				["tempJobEstimation", "services"],
				state.getIn(["jobEstimation", "services"])
			);

		//------------------------------------------------

		case jobConstants.RESET_TEMP_JOB_ESTIMATION:
			return state.setIn(
				["tempJobEstimation", "disciplines"],
				state.getIn(["jobEstimation", "disciplines"])
			);

		case jobConstants.CLEAR_JOB_ESTIMATION:
			return state.set("tempJobEstimation", Map()).set("jobEstimation", Map());

		// Assigned users reducer functions
		case jobConstants.ADD_TEMP_ASSIGNED_USER:
			return addTempAssignedUser(action.data);

		case jobConstants.REMOVE_TEMP_ASSIGNED_USER:
			return removeTempAssignedUser(action.data);

		case jobConstants.UPDATE_TEMP_ASSIGNED_USER:
			return updateTempAssignedUser(action.data);

		case jobConstants.ADD_TEMP_DELIVERY_DATE:
			return addTempDeliveryDate(action.data);

		case jobConstants.RESET_TEMP_ASSIGNED_USERS:
			return state.set("tempAssignedUsers", state.get("assignedUsers"));

		case jobConstants.CLEAR_TEMP_ASSIGNED_USERS:
			return state.set(
				"tempAssignedUsers",
				initialState.get("tempAssignedUsers")
			);

		case jobConstants.GET_ASSIGNED_USERS:
			return getAssignedUsers(action.data);

		case jobConstants.GET_DELIVERY_DATE:
			return getDeliveryDate(action.data);

		case jobConstants.GET_JOB_COMPANIES:
			return state.set("jobCompanies", fromJS(action.data));

		case jobConstants.GET_JOB_USERS:
			return state.set("jobUsers", fromJS(action.data));

		case jobConstants.UPDATE_ASYNC_LOAD_JOBS:
			return updateAsyncLoadJobs(action.data);

		case jobConstants.GET_JOB_VIEWER:
			return state.set("jobViewer", fromJS(action.data));

		case jobConstants.RESET_JOB_EXPORTS:
			return state.setIn(
				["tempJob", "exports"],
				state.getIn(["tempJob", "initialExports"])
			);

		default:
			return state;
	}
};
