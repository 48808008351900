import React, { useState } from "react";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { deleteLODSpecification, deletePackage } from "../../../../../actions";
import localize, { LocKeys } from "../../../../../constants/localizations";
import EditButton from "../../../../../shared/components/buttons/EditButton";
import HeaderLayout from "../../../../layout/content/HeaderLayout";
import Modal from "../../../../Modal";
import { canEditPackage } from "../../../permission";
import Logo from "../../../../../shared/Logo/Logo";
import {
	logoTypesConstants,
	sizeConstants,
} from "../../../../../constants/constants";
import ComponentTooltip from "../../../../../shared/components/component-tooltip/ComponentTooltip";

const HeaderView = (props) => {
	const {
		companyImageLink,
		isPublic,
		companyName,
		name,
		toggleEditView,
		deletePackage,
		lodSpecificationId,
		companyId,
		resellerCompanyId,
		deleteLODSpecification,
	} = props;
	const { packageId } = useParams();
	const history = useHistory();
	const [removeModal, setRemoveModal] = useState(false);

	const toggleRemoveModal = () => {
		setRemoveModal(!removeModal);
	};

	return (
		<>
			<Modal
				show={removeModal}
				title={
					localize(LocKeys.DELETE) +
					" " +
					localize(LocKeys.PACKAGE).toLowerCase()
				}
				onClose={toggleRemoveModal}
			>
				<ReactMarkdown>
					{localize(LocKeys.ARE_YOU_SURE_DELETE, [name])}
				</ReactMarkdown>
				<div className="form-contents__wrapper d-flex justify-content-end mt-32">
					<button
						type="button"
						onClick={() => toggleRemoveModal()}
						className="btn btn--secondary-error"
					>
						{localize(LocKeys.CANCEL)}
					</button>
					<button
						type="button"
						onClick={(e) => {
							deletePackage(packageId, () => {
								deleteLODSpecification(lodSpecificationId);
								history.goBack();
							});
							toggleRemoveModal();
						}}
						className="btn btn--error ml-m"
					>
						{localize(LocKeys.DELETE)}
					</button>
				</div>
			</Modal>
			<HeaderLayout wrapperClasses={`px-48 d-flex align-items-center `}>
				<div className="d-flex align-items-center justify-content-between w-100">
					<ComponentTooltip content={name} direction={"top"}>
						<h3 className="text-overflow-ellipsis max-w-550p">{name}</h3>
					</ComponentTooltip>
					<div className="d-flex align-items-center">
						{!isPublic && (
							<div className="d-flex align-items-center">
								<Logo
									link={companyImageLink}
									alt={companyName}
									wrapperClasses="mr-s"
									type={logoTypesConstants.COMPANY}
									size={sizeConstants.SMALL}
								/>
								<ComponentTooltip content={companyName} direction={"top"}>
									<h4 className="text-overflow-ellipsis max-w-340p">
										{companyName}
									</h4>
								</ComponentTooltip>
							</div>
						)}
						{canEditPackage(companyId, resellerCompanyId) && (
							<div className={`d-flex  align-items-center ml-m`}>
								<div>
									<button
										onClick={() => toggleRemoveModal()}
										className="btn btn--secondary-error btn--small"
									>
										{localize(LocKeys.DELETE)}
									</button>
								</div>

								<div className="ml-m">
									<EditButton onClick={() => toggleEditView(true)} />
								</div>
							</div>
						)}
					</div>
				</div>
			</HeaderLayout>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		name: state.getIn(["dashboard", "salesPackage", "name"]),
		companyName: state.getIn(["dashboard", "salesPackage", "company", "name"]),
		companyImageLink: state.getIn([
			"dashboard",
			"salesPackage",
			"company",
			"imageLink",
		]),
		isPublic: state.getIn([
			"dashboard",
			"salesPackage",
			"lodSpecification",
			"isPublic",
		]),
		companyId: state.getIn(["dashboard", "salesPackage", "company", "id"]),
		resellerCompanyId:
			state.getIn([
				"dashboard",
				"salesPackage",
				"company",
				"resellerCompany",
				"id",
			]) || null,
		lodSpecificationId: state.getIn([
			"dashboard",
			"salesPackage",
			"lodSpecification",
			"id",
		]),
	};
};

export default connect(mapStateToProps, {
	deletePackage,
	deleteLODSpecification,
})(HeaderView);
