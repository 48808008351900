import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import {
	clearTempLodSpecification,
	getDisciplines,
	getLods,
	getLodSpecificationBasic,
	updateLodSpecification,
} from "../../../../../actions";
import localize, { LocKeys } from "../../../../../constants/localizations";
import EditButton from "../../../../../shared/components/buttons/EditButton";
import SectionHeader from "../../../../orders/single-view/parts/SectionHeader";
import SingleSpecification from "../../../../orders/single-view/view/specification-summary/SingleSpecification";
//import { canEditPackage } from "../../../permission";
import SpecificationContent from "../../../../../shared/specification-content/SpecificationContent";
import { isBimify } from "../../../../../utils/permission/user";

const PackageSpecification = (props) => {
	const {
		name,
		// companyId,
		// resellerCompanyId,
		cancelFormOrder = false,
		disciplineComponentCategoryLods,
		toggleEditView = null,
		lodSpecification,
		getLodSpecificationBasic,
		getDisciplines,
		lodSpecificationId,
		clearTempLodSpecification,
		getLods,
		updateLodSpecification,
	} = props;

	useEffect(() => {
		if (lodSpecificationId) {
			if (!cancelFormOrder)
				getLodSpecificationBasic({
					id: lodSpecificationId,
					permission: true,
				});

			getDisciplines({});
			getLods({});
		}
	}, [
		cancelFormOrder,
		getLodSpecificationBasic,
		getDisciplines,
		lodSpecificationId,
		getLods,
	]);

	const setValue = useCallback(
		(data) => {
			updateLodSpecification(data);
		},
		[updateLodSpecification]
	);

	return (
		<div>
			<div className="d-flex align-items-center justify-content-between w-100 mb-regular">
				<SectionHeader title={localize(LocKeys.LOD_SPECIFICATION)} />
				{toggleEditView && isBimify() && (
					<EditButton
						disabled={!!name ? false : true}
						onClick={() => {
							clearTempLodSpecification();
							toggleEditView(false, true);
						}}
					/>
				)}
			</div>

			{isBimify() ? (
				<SpecificationContent
					updateSelected={setValue}
					initialSelected={disciplineComponentCategoryLods}
					jobView={false}
					editable={true}
					//editView={true}
					isForPackage={true}
				/>
			) : (
				<SingleSpecification
					disciplineComponentCategoryLods={
						lodSpecification?.toJS()
							?.lodSpecificationDisciplineComponentCategoryLods
					}
				/>
			)}
			{/* <SingleSpecification
				disciplineComponentCategoryLods={
					lodSpecification?.toJS()
						?.lodSpecificationDisciplineComponentCategoryLods
				}
			/> */}
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		lodSpecification: state?.getIn(["lodSpecification", "lodSpecification"]),
		name: state?.getIn(["lodSpecification", "lodSpecification", "name"]),
		lodSpecificationId: state.getIn([
			"dashboard",
			"salesPackage",
			"lodSpecification",
			"id",
		]),
		disciplineComponentCategoryLods: state.getIn([
			"lodSpecification",
			"tempLodSpecification",
			"disciplineComponentCategoryLods",
		]),
		companyId: state
			.getIn([
				"lodSpecification",
				"lodSpecification",
				"lodSpecificationCompanies",
			])
			?.get(0)
			?.getIn(["company", "id"]),
		resellerCompanyId: state
			.getIn([
				"lodSpecification",
				"lodSpecification",
				"lodSpecificationCompanies",
			])
			?.get(0)
			?.getIn(["company", "resellerCompanyId"]),
	};
};
export default connect(mapStateToProps, {
	getLodSpecificationBasic,
	getDisciplines,
	clearTempLodSpecification,
	getLods,
	updateLodSpecification,
})(PackageSpecification);
