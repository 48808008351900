import { adornmentTypeConstants } from "../../../../constants/constants";
import localize, { LocKeys } from "../../../../constants/localizations";

export const customAdjustmentConstants = {
	percentage: "percentage",
	time: "time",
	velocity: "velocity",
};

export const getCustomAdjustmentType = (constant) => {
	switch (constant) {
		case customAdjustmentConstants.percentage:
			return adornmentTypeConstants.PERCENTAGE;

		case customAdjustmentConstants.time:
			return adornmentTypeConstants.HOURS;

		case customAdjustmentConstants.velocity:
			return adornmentTypeConstants.SQUARE_METER_HOUR;

		default:
			return null;
	}
};

export const getCustomAdjustmentTypeLabel = (constant) => {
	switch (constant) {
		case customAdjustmentConstants.percentage:
			return localize(LocKeys.PERCENTAGE);

		case customAdjustmentConstants.time:
			return localize(LocKeys.TIME);

		case customAdjustmentConstants.velocity:
			return localize(LocKeys.VELOCITY);

		default:
			return null;
	}
};

export const typeOptions = [
	{
		label: localize(LocKeys.PERCENTAGE),
		value: customAdjustmentConstants.percentage,
	},
	{
		label: localize(LocKeys.TIME),
		value: customAdjustmentConstants.time,
	},
	{
		label: localize(LocKeys.VELOCITY),
		value: customAdjustmentConstants.velocity,
	},
];

export const saveAdjustmentData = (data, type, value) => {
	data["timeAdjustment"] = null;
	data["percentageAdjustment"] = null;
	data["velocityAdjustment"] = null;

	if (type === customAdjustmentConstants.time) {
		data["timeAdjustment"] = +value;
	} else if (type === customAdjustmentConstants.percentage) {
		data["percentageAdjustment"] = +value;
	} else if (type === customAdjustmentConstants.velocity) {
		data["velocityAdjustment"] = +value;
	}
};

export const saveCustomAdjustmentData = (data, type, value) => {
	data["customTimeAdjustment"] = null;
	data["customPercentageAdjustment"] = null;
	data["customVelocityAdjustment"] = null;

	if (type === customAdjustmentConstants.time) {
		data["customTimeAdjustment"] = +value;
	} else if (type === customAdjustmentConstants.percentage) {
		data["customPercentageAdjustment"] = +value;
	} else if (type === customAdjustmentConstants.velocity) {
		data["customVelocityAdjustment"] = +value;
	} else {
		data["customTimeAdjustment"] = +value;
		// data["customPercentageAdjustment"] = null;
		// data["customVelocityAdjustment"] = null;
	}
};
